<template>
  <v-theme-provider root>
    <base-section
      id="book-a-demo"
      space="40"
      height="93"
    >
      <v-container>
        <v-row
          justify="center"
        >
          <v-window v-model="step">
            <v-window-item :value="1">
              <base-title
                :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
                title="Book your personal lavvira demo"
                class="mt-16 mb-2 white--text"
              />
              <base-body class="mt-3">
                <v-card
                  class="d-flex"
                  flat
                >
                  <v-date-picker
                    v-model="picker"
                    color="primary"
                    elevation="5"
                    :class="$vuetify.breakpoint.mdAndUp ? 'mx-6' : 'ml-4'"
                    :full-width="$vuetify.breakpoint.mdAndUp"
                  />
                <!-- :landscape="$vuetify.breakpoint.mdAndUp" -->
                </v-card>
              </base-body>
            </v-window-item>
            <v-window-item :value="2">
              <base-title
                :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
                title="Enter contact data"
                class="mt-16 mb-2 white--text"
              />

              <base-body>
                <v-img
                  style="background-color: #234e79; background-image: linear-gradient(110deg, #234e79 0%, #9895e2 100%);"
                >
                  <v-card
                    color="transparent"
                    flat
                    class="pa-14"
                  >
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                    >
                      <v-text-field
                        v-model="picker"
                        label="Selected date"
                        outlined
                        dark
                        dense
                        color="secondary"
                        disabled
                      />

                      <v-dialog
                        ref="dialog"
                        v-model="modal2"
                        :return-value.sync="time"
                        persistent
                        width="90vw"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time"
                            label="Choose a suitable time"
                            prepend-inner-icon="mdi-clock-time-four-outline"
                            format="24hr"
                            outlined
                            dark
                            dense
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-time-picker
                          v-if="modal2"
                          v-model="time"
                          full-width
                          :landscape="$vuetify.breakpoint.mdAndUp"
                          scrollable
                          color="secondary"
                        >
                          <v-spacer />
                          <v-btn
                            text
                            color="primary"
                            @click="modal2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>

                      <v-text-field
                        v-model="name"
                        :counter="50"
                        :rules="nameRules"
                        label="Personal name / Company name"
                        outlined
                        dark
                        dense
                        color="secondary"
                        required
                      />

                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        outlined
                        dark
                        dense
                        color="secondary"
                        required
                      />

                      <v-checkbox
                        v-model="checkbox"
                        :rules="[v => !!v || 'You must agree to continue!']"
                        label="Do you agree our terms and conditions?"
                        dark
                        required
                      />

                      <span style="font-size: 12px;">*All fields are required</span>

                      <v-row
                        align="center"
                        justify="space-between"
                        class="mt-4"
                      >
                        <v-btn
                          outlined
                          rounded
                          color="white"
                          class="ml-5"
                          small
                          @click="step = 1; picker = null"
                        >
                          <v-icon>
                            mdi-undo
                          </v-icon>
                          back
                        </v-btn>

                        <v-btn
                          :disabled="disabledButton"
                          color="success"
                          class="mr-5"
                          rounded
                          right
                          small
                          @click="validate"
                        >
                          Send request
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-card>
                </v-img>
              </base-body>
            </v-window-item>
          </v-window>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBookADemo',
    data: () => ({
      picker: null,
      step: 1,
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      time: null,
      modal2: false,
      checkbox: false,
    }),
    computed: {
      disabledButton () {
        let rule
        if (!this.valid || !this.checkbox || !this.name || !this.email || this.time === null) {
          rule = true
        }
        return rule
      },
    },
    watch: {
      picker (value) {
        if (value) {
          this.step = 2
        }
      },
    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>
